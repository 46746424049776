import {useEffect, useRef, useState} from 'react';
import {Card, ListGroup, Modal, Placeholder} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faArrowRight, faCalculator} from "@fortawesome/free-solid-svg-icons";

import {TileDescription, TileTitle} from "../../Core/Tile/TileComponents";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import {LoadingSpinner, Text} from "@amboss/design-system";
import {useSelector} from "react-redux";
import {selectCurrentUserSpecialty} from "../../../redux/slices/userSlice";
import {selectCurrentPatientConditions} from "../../../redux/slices/conditionSlice";


function CalculatorTile({ onModalOpen, outlink }) {
    const isMounted = useRef(true);

    const [mdCalcs, setMDCalcs] = useState([]);
    const [qxMDCalcs, setQxMDCalcs] = useState([]);
    const [errorLoadingCalcs, setErrorLoadingCalcs] = useState(null)
    const [isLoadingCalcs, setLoadingCalcs] = useState(false)
    const [selectedScore, setSelectedScore] = useState(null);
    const [modalShow, setModalShow] = useState(false);
    const [searchTerm, setSearchTerm] = useState(null)

    // Fetching parameters for calculator search from redux
    const currentUserSpecialty = useSelector(selectCurrentUserSpecialty);
    const currentPatientConditions = useSelector(selectCurrentPatientConditions);

    const displayLimit = 5;

    useEffect(() => {
        // Format requestData into respective format
        const conditions = currentPatientConditions.map(e => e.display);

        const requestData = {
            specialty: Array.isArray(currentUserSpecialty) ? currentUserSpecialty : [currentUserSpecialty],
            conditions: Array.isArray(conditions) ? conditions : [conditions],
            //chiefComplaints: Array.isArray(chiefComplaints) ? chiefComplaints : [chiefComplaints], // not needed for now
            queryString: searchTerm
        };

        // Remove any undefined or null properties from the request data
        Object.keys(requestData).forEach(key => requestData[key] == null && delete requestData[key]);

        fetch('/api/tools/calculator/find/related',{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestData)
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                if (isMounted.current) {
                    setMDCalcs(data.mdResults);
                    setQxMDCalcs(data.uniqueAmbossResults);
                }
                setLoadingCalcs(false);
            })
            .catch(error => {
                setErrorLoadingCalcs(error);
                setLoadingCalcs(false);
            });
    }, [currentPatientConditions]);


    const handleModalOpen = (calc) => {
        setSelectedScore(calc);
        setModalShow(true);
    };

    console.log("mdCalcs: ",mdCalcs)
    console.log("qxMDCalcs: ",qxMDCalcs)

    return (
        <Card className="tile">
            <Card.Header className="d-flex justify-content-center align-items-center" style={{ height: '180px' }}>
                <FontAwesomeIcon icon={faCalculator} size="5x" />
            </Card.Header>
            <Card.Body>
                <TileTitle>Calculator</TileTitle>
                <TileDescription>Description for the Calculator</TileDescription>
            </Card.Body>

            {qxMDCalcs?.length > 0 || mdCalcs?.length > 0 ? (
                <ListGroup className="list-group-overflow">

                    {qxMDCalcs.map((calc, index) => (
                        <ListGroup.Item
                            key={index}
                            className="calculator-list"
                            role="button"
                            onClick={() => handleModalOpen(calc)}
                        >
                            {calc.title}
                            <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                        </ListGroup.Item>
                    ))}

                    {mdCalcs.map((calc, index) => (
                        <ListGroup.Item
                            key={index}
                            className="calculator-list"
                            role="button"
                        >
                            {calc.title}
                            <a href={`https://www.mdcalc.com/calc/${calc.id}/`} target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faExternalLinkAlt} className="ml-2" />
                            </a>
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            ) : (
                <ListGroup className="list-group-flush">
                    { isLoadingCalcs ?
                        <LoadingSpinner screenReaderText={"Loading"} size={50} />
                        :
                        errorLoadingCalcs ?
                            <Text>Error loading calculators: {errorLoadingCalcs.message}</Text>
                            :
                            <Text>No Calculators found …</Text>
                    }
                </ListGroup>
            )}

            <Card.Body>
                <Button href={outlink} variant="primary">Go to MdCalc</Button>
            </Card.Body>

            <Modal size="xl" show={modalShow} centered={true} autoFocus={true} onHide={() => setModalShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedScore ? selectedScore.title : 'Calculator'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedScore && <iframe src={selectedScore.url} width="100%" height="700px" title="Embedded Calculator"></iframe>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setModalShow(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
        </Card>
    );



}

export default CalculatorTile;
