import {Box, Column, Container, MediaItem, SearchResult} from "@amboss/design-system";
import {Link} from "react-router-dom";

function SearchResultItem({ result, abstract }) {
    const combinedSecondaryTargets = [...result.particles, ...result.anchors]
        .sort((a, b) => b.count - a.count)
        .map(item => ({
            title: item.name || 'Placeholder',
            link: ({ children }) => <Link href={`https://next.amboss.com/us/article/${result.articleEid}#${item.particleEid || item.anchorId}`} color="tertiary">{children}</Link>,
            body: 'Placeholder'
        }));

    return (
        <Column size={6}><Container elevation={2}><Box space={"s"}>
            <SearchResult
                title={result.metaData.title}
                subtitle={result.metaData.knowledgeCategory}
                details={abstract.split(". ")}
                labels={result.metaData.stages}
                link={({ children }) => <a href={`https://next.amboss.com/us/article/${result.articleEid}`} style={{ textDecoration: "none" }}>{children}</a>}
                secondaryTargets={combinedSecondaryTargets}
            />
            {result.metaData.firstMedia?.filter(e => e === 4).map(media => (
                <div
                    style={{
                        border: '1px solid rgba(163, 178, 189, 0.3)',
                        borderRadius: 6,
                        height: 100,
                        width: 200
                    }}
                >
                    <MediaItem
                        fit="contain"
                        src={media.editorialLink.url}
                        title={media.title}
                    />
                </div>
            ))}
        </Box></Container></Column>
    );
}

export default SearchResultItem
