import { useLocation } from 'react-router-dom';
import {pageIconMap} from "../utils/pageIconMap";

export function useNavigationItems() {
    const location = useLocation();
    const currentTab = location.pathname.slice(1);

    const tabLabels = ['tools', 'patient', 'search', 'userCenter', 'settings'];
    const disabledSites = ['usercenter', 'settings'];

    const tabs = tabLabels.map(label => ({
        icon: pageIconMap[label],
        label: label.charAt(0).toUpperCase() + label.slice(1),
        disabled: (disabledSites.includes(label.toLowerCase())),
        isActive: label.toLowerCase() === currentTab
    }));

    return tabs;
}
