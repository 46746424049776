import React from 'react';
import axios from 'axios';

import { Input } from "@amboss/design-system";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectCurrentUserEid} from "../../../redux/slices/userSlice";

function SearchBar({ onInputChange, handleSearchSubmit, searchTerm, onBlur, onFocus, onMouseEnter, setShowSearchSuggestions }) {

    return (
        <div className={"header__searchbar"}>
            <form className={"header__searchbar--form"}
                  onSubmit={(e) => {
                      e.preventDefault();
                      handleSearchSubmit()
                  }}
            >
                <Input
                    className={"header__searchbar--input"}
                    name="header-searchbar"
                    onBlur={onBlur}
                    onChange={onInputChange}
                    onMouseEnter={onMouseEnter}

                    onFocus={onFocus}
                    placeholder="Search"
                    type="text"
                    value={searchTerm}
                    autoComplete={"on"}
                />
            </form>
        </div>
    );
}

export default SearchBar;
