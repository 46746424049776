import {createContext, useContext, useEffect, useState} from 'react';
import {logoutUser, updateUser} from "../redux/slices/userSlice";
import {useDispatch} from "react-redux";

const UserContext = createContext();

export const UserProvider = ({children}) => {
    const [loggedIn, setLoggedIn] = useState(false);
    const [confirmedProfessional, setConfirmedProfessional] = useState( false)
    const [triggerCheck, setTriggerCheck] = useState(0);

    const dispatch = useDispatch();

    useEffect(() => {
        async function verifyUser() {
            try {
                const response = await fetch('/api/auth/medicuja/verify');
                const data = await response.json();

                if (data.success) {
                    setLoggedIn(true);
                    setConfirmedProfessional(data.confirmedProfessional);
                    dispatch(updateUser(data))
                } else {
                    setLoggedIn(false);
                    dispatch(logoutUser())
                }
            } catch (error) {
                console.error("Error verifying user authentication:", error);
            }
        }
        console.log("Checked amboss user auth status")

        verifyUser();
    }, [triggerCheck]);

    const forceUserCheck = () => {
        setTriggerCheck(prev => prev + 1);
    }

    return(
        <UserContext.Provider value={{ loggedIn, setLoggedIn, confirmedProfessional, setConfirmedProfessional, forceUserCheck }}>
            {children}
        </UserContext.Provider>
    )
}

export const useUser = () => useContext(UserContext);
