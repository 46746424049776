import React, {useRef, useState} from 'react';
import Header from './components/Header/Header';
import Tools from './components/Body/Tools';
import Patient from './components/Body/Patient';
import Search from './components/Body/Search/Search';
import UserCenter from './components/Body/UserCenter';
import Settings from './components/Body/Settings';
import Footer from './components/Footer/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/css/styles.css'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectCurrentPatientId,
    selectCurrentPatientName,
    selectCurrentPatientFirstName, fetchPatient
} from './redux/slices/patientSlice';
import {fetchObservation} from "./redux/slices/observationSlice";
import {fetchCondition} from "./redux/slices/conditionSlice";
import {Box, Callout, Container} from "@amboss/design-system";

import {Route, Routes} from "react-router-dom";
import PatientCallout from "./components/Header/PatientCallout/PatientCallout";
import Dashboard from "./components/Body/Dashboard";


function App(props) {
    const {theme, setTheme} = props;
    const [isDomLoaded, setIsDomLoaded] = useState(false);
    const [hasSearchBar, showSearchBar] = useState(false)

    const headerRef = useRef(null);

    const dispatch = useDispatch();
    const patientStatus = useSelector(state => state.patient?.status);
    const patientData = useSelector(state => state.patient?.currentPatient?.data)
    const patientId = useSelector(selectCurrentPatientId);
    const currentPatientFirstName = useSelector(selectCurrentPatientFirstName);
    const currentPatientName = useSelector(selectCurrentPatientName);
    const currentPatientId = useSelector(selectCurrentPatientId);





    useEffect(() => {
        if (patientStatus === 'idle') {
            dispatch(fetchPatient());
        }
    }, [patientStatus, dispatch]);

    useEffect(() => {
        if (patientStatus === "succeeded") {
            dispatch(fetchObservation());
            dispatch(fetchCondition());
        }
    }, [dispatch, patientStatus, patientId]);

    return (
        <Container elevation={0} className="App">
            <Box space={["s","m","xl"]} bSpace={["xxs","m","m"]}><Header ref={headerRef} theme={theme} setTheme={setTheme} hasSearchBar={hasSearchBar} showSearchBar={showSearchBar} /></Box>
            <Box role={"p"} space={"s"} lSpace={["m","m","xl"]} rSpace={["m","m","xl"]} className="patient-info-bar">
                <PatientCallout
                    patientStatus={patientStatus}
                    currentPatientFirstName={currentPatientFirstName}
                    currentPatientName={currentPatientName}
                    currentPatientId={currentPatientId}
                />
            </Box>
                <div className="amboss-ds-portal" style={{zIndex: 100, position: "absolute"}} />
                <Routes>
                    <Route path="/tools" element={<Tools />} />
                    <Route path="/patient" element={<Patient />} />
                    <Route path="/search" element={<Search />} />
                    <Route path="/userCenter" element={<UserCenter />} />
                    <Route path="/settings" element={<Settings />} />

                    <Route path="/" element={<Dashboard />} />
                </Routes>
                <Footer />

        </Container>
    );
}

export default App;
