import {Card, ListGroup, Placeholder} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckSquare} from "@fortawesome/free-solid-svg-icons";
import {TileDescription, TileTitle} from "../../Core/Tile/TileComponents";

function SegmentedButtonDropdownsExample() {
    return null;
}

function ChecklistTile({listItems}) {
    return (
        <Card className="tile">
            <Card.Header className="d-flex justify-content-center align-items-center" style={{ height: '180px' }}>
                <FontAwesomeIcon icon={faCheckSquare} size="5x" />
            </Card.Header>
            <Card.Body>
                <TileTitle>Checklist</TileTitle>
                <TileDescription>Description for the Checklist</TileDescription>

                {!listItems.includes(null) ? (
                    <ListGroup>
                        {listItems.map((item, index) => (
                            <ListGroup.Item key={index}>
                                {item}
                                <SegmentedButtonDropdownsExample />
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                ) : (
                    <Placeholder as="li" animation="glow"><Placeholder xs={5} /></Placeholder>
                )}
            </Card.Body>
        </Card>
    );
}

export default ChecklistTile
