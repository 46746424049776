import {Modal, ButtonGroup, LoadingSpinner} from '@amboss/design-system';
import {useEffect, useState} from "react";
import LoginModalForm from "./LoginModalForm";

function LoginModal({ setLoginModalOpen, isLoginModalOpen, open }) {
    const [modalActionType, setModalActionType] = useState('none') // Translate current modalAction
    const [isLoading, setIsLoading] = useState(false); // Loading state for the login request
    const [error, setError] = useState(''); // Formal errors in the form
    const [success, setSuccess] = useState(false) // Tracks success of login request
    const [attempts, setAttempts] = useState(0) // Tracking login attempts for better UX

    const handleLogin = (success, message) => {
        if (success) {
            setSuccess(true);
            setError(message);
            setTimeout(() => setLoginModalOpen(false), 2500);
        } else {
            setError(message);
        }
        setIsLoading(false);
    };

    return (
        <Modal
            header="Login to AMBOSS"
            labelHeader="Want cooler features?"
            onAction={(action) => {
                if (action === "cancel" && open === true) {
                    setLoginModalOpen(false);
                }
                if (open === false) open = true;
            }}
            privateProps={{ skipPortal: false }}
            role="dialog"
        >
            <Modal.Stack>
                {
                    isLoading ?
                        <LoadingSpinner screenReaderText="Loading"/>
                        :
                        success ?
                            <Modal.Text>Login successful! Redirecting ...</Modal.Text>
                            :
                            <>
                                <Modal.Text>Please log in to access your account.</Modal.Text>
                                <LoginModalForm
                                    setError={setError}
                                    error={error}
                                    modalActionType={modalActionType}
                                    onLogin={handleLogin}
                                    setIsLoading={setIsLoading}
                                    attempts={attempts}
                                    setAttempts={setAttempts}
                                />
                            </>
                }
                {
                    !( isLoading || success ) &&
                        <ButtonGroup
                            className="login-modal-button"
                            actionButton={{
                                text: 'Login',
                                variant: 'primary'
                            }}
                            cancelButtonText="Cancel"
                            onButtonClick={(actionType) => {
                                if (actionType === 'cancel') {
                                    setModalActionType('cancel')
                                    setLoginModalOpen(false)
                                } else if (actionType === 'action' && !error) {
                                    setIsLoading(true);
                                    setModalActionType('action')
                                }
                            }}
                        />
                }
            </Modal.Stack>
        </Modal>
    );
}

export default LoginModal;

