import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CalculatorTile from './Tools/CalculatorTile';  // Assuming you've separated tiles into individual files.
import ChecklistTile from './Tools/Checklist';
import TranslatorTile from './Tools/Translator';
import { setCalculatorData } from '../../redux/slices/toolsSlice';
import '../../styles/css/tools.css';
import {LoadingSpinner} from "@amboss/design-system";


const ToolsPage = () => {
    const dispatch = useDispatch();
    const calculatorData = useSelector(state => state.tools.calculator.data);

    useEffect(() => {
        if (!calculatorData) {
            fetchCalculatorData().then(data => {
                dispatch(setCalculatorData(data));
            });
        }
    }, [calculatorData, dispatch]);

    const fetchCalculatorData = async () => {
        try {
            const response = await fetch('/api/tools/calculator/scores');
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error fetching calculator data:', error);
            return null;
        }
    };


    return (
        <div className="container mt-5 mb-5">
            <div className="row justify-content-start">
                {/* Calculator Tile */}
                <div className="tile col-md-4">
                    <CalculatorTile
                        scores={[calculatorData]}
                        onModalOpen={() => console.log("Calculator modal clicked")}
                        outlink="#"
                    />
                </div>

                {/* Checklist Tile (Place it next to the Calculator Tile) */}
                <div className="tile col-md-4">
                    <ChecklistTile listItems={["abc","hdh"]} />
                </div>

                {/* Translator Tile (Spans across the second row, wider than the rest) */}
                <div className="tile col-md-4">
                    <TranslatorTile languagePairs={["my grandma likes hiking.","who is behind the bush?"]} />
                </div>
            </div>
        </div>
    );
};

export default ToolsPage;
