import React, { useContext } from 'react';
import Placeholder from 'react-bootstrap/Placeholder';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';

export const LoadingContext = React.createContext();

export const TileTitle = ({ children }) => {
    const isLoading = useContext(LoadingContext);
    if (isLoading) {
        return <Placeholder as="h5" animation="glow"><Placeholder xs={6} /></Placeholder>;
    }
    return <Card.Title>{children}</Card.Title>;
};

export const TileDescription = ({ children }) => {
    const isLoading = useContext(LoadingContext);
    if (isLoading) {
        return <Placeholder as="p" animation="glow"><Placeholder xs={7} /></Placeholder>;
    }
    return <Card.Text>{children}</Card.Text>;
};

export const TileListGroupItem = ({ children }) => {
    const isLoading = useContext(LoadingContext);
    if (isLoading) {
        return <Placeholder as="li" animation="glow"><Placeholder xs={5} /></Placeholder>;
    }
    return <ListGroup.Item>{children}</ListGroup.Item>;
};
