import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    currentUser: null,
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        updateUser: (state, action) => {
            state.currentUser = {
                ...state.currentUser,
                ...action.payload.currentUser,
                loggedIn: true,
            };
        },
        logoutUser: (state) => {
            state.currentUser = {
                loggedIn: false
            }
        }
    }
});

export const selectCurrentUserFirstName = (state) => state.user.currentUser?.firstName;
export const selectCurrentUserLastName = (state) => state.user.currentUser?.lastName;
export const selectCurrentUserSpecialty = (state) => state.user.currentUser?.specialty;
export const selectCurrentUserEid = (state) => state.user.currentUser?.eid;

export const { updateUser, logoutUser } = userSlice.actions;
export default userSlice.reducer;
