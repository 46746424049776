import React, {useState} from 'react';
import {Box, Columns, H1, Stack, Text} from "@amboss/design-system";
import {useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import SearchResultItem from "./Search/SearchResultItem";
import {stripHTML} from "../../utils/oneLinerFunctions";
import CalculatorTile from "./Tools/CalculatorTile";

function Dashboard () {
    const location = useLocation();
    const searchResults = useSelector(state => state.search?.results);
    const searchTerm = location.state?.searchTerm || "";
    const [abstracts, setAbstracts] = useState([]);

    return (
        <Box className="dashboard">
            <Stack><H1>Dashboard</H1>
            <Text as={"p"}>Here is the information about the search and other details.</Text>
                {searchTerm
                    ? <Text as={"p"}>Your search was about: {"\n"}{searchTerm}</Text>
                    : <Text as={"p"}>It seems you haven't run a search yet, or maybe there was a problem.</Text>
                }
            </Stack>
            <Box space={"s"}>
                <Columns gap={"xl"}>
                    {searchResults?.searchSuggestionInstantResults?.filter(result => result.articleEid).slice(0, 2).map((result, index) => (
                        <SearchResultItem result={result} abstract={stripHTML(abstracts[index])} />
                    ))}
                </Columns>
            </Box>
            <CalculatorTile />
        </Box>
    );
};

export default Dashboard;
