// rootReducer.js
import { combineReducers } from 'redux';
import patientSlice from "./slices/patientSlice";
import toolsSlice from "./slices/toolsSlice";
import observationSlice from "./slices/observationSlice";
import conditionSlice from "./slices/conditionSlice";
import userSlice from "./slices/userSlice";
import searchSlice from "./slices/searchSlice";

const rootReducer = combineReducers({
    patient: patientSlice,
    observation: observationSlice,
    condition: conditionSlice,
    tools: toolsSlice,
    user: userSlice,
    search: searchSlice
    // ... other slices
});

export default rootReducer;
