import React from 'react';

function Terms() {
    return (
        <div className="terms">
            {/* Terms and conditions */}
        </div>
    );
}

export default Terms;
