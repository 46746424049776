import 'core-js/stable';
import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './redux/store';

import App from './App';
import {Box, Container, dark, light, LoadingSpinner, Stack, ThemeProvider} from "@amboss/design-system";
import {ViewportProvider} from "./Context/ViewportContext";
import {CSRFProvider} from "./Context/CSRFContext";
import {BrowserRouter} from "react-router-dom";
import {UserProvider} from "./Context/UserContext";

const Page = (props) => {
    const [theme, setTheme] = useState(false)

    const firstUpdate = useRef(true);
    useLayoutEffect(() => {});

    useLayoutEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
        } else {
            document.body.classList.toggle("dark-theme");
        }
    }, [theme]);

    return(
        <ThemeProvider theme={theme ? dark : light}>
            <Provider store={store}>
                <UserProvider>
                    <BrowserRouter>
                        <ViewportProvider>
                            <CSRFProvider>
                                    <Stack space="xxl">
                                        <Container elevation={0}>
                                            <App theme={theme} setTheme={setTheme} />
                                        </Container>
                                    </Stack>
                            </CSRFProvider>
                        </ViewportProvider>
                    </BrowserRouter>
                </UserProvider>
            </Provider>
        </ThemeProvider>

    )
}

ReactDOM.render(<Page />, document.getElementById('root')
);
