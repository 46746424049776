import React, { useEffect, useState } from 'react';
import {Button, Divider, Inline, Select, Stack, Tag, Text} from "@amboss/design-system";
import { useSelector } from "react-redux";
import { selectCurrentUserEid } from "../../../redux/slices/userSlice";
import { addTag, deleteTag, fetchTags } from "../../../utils/favoriteTagFunctions";

const FavoriteTags = ({ category }) => {
    const availableTags = {
        labs: ['Ca2+', "CRP", "Pt+"],
        meds: ['Penicillin i.v. 500mg', "Ibuprofen p.o. 400mg", "Acetaminophen p.o. 500mg"],
        scores: ['GCS','Child-Pugh-Score','CHADSVASC', "HAS-BLED"]
    }
    const [tags, setTags] = useState([]);
    const [selectedTag, setSelectedTag] = useState(null);
    const currentUserEid = useSelector(selectCurrentUserEid);

    useEffect(() => {
        console.log(tags)
        const loadTags = async () => {
            const fetchedTags = await fetchTags(category, currentUserEid);
            setTags(fetchedTags);
        };

        loadTags();
    }, [currentUserEid, category]);

    const handleTagChange = (event) => {
        setSelectedTag(event.target.value);
    };

    const handleAddTag = async () => {
        if (selectedTag && !tags.includes(selectedTag)) {
            const updatedTags = await addTag(category, currentUserEid, selectedTag);
            setTags(updatedTags);
        }
    };

    const handleDeleteTag = async (tagToDelete) => {
        const updatedTags = await deleteTag(category, currentUserEid, tagToDelete);
        setTags(updatedTags);
    };

    return (
        <Stack spacing="medium">
            <Inline vAlignItems={"bottom"}>
                <Select
                    maxHeight={130}
                    label={`Favorite ${category}`}
                    options={availableTags[category]?.filter(e => !tags?.includes(e)).map(tag => ({ label: tag, value: tag }))}
                    value={selectedTag}
                    onChange={handleTagChange}
                    placeholder={`Add a ${category} tag`}
                />
                <Button onClick={handleAddTag} variant="primary" size="m">
                    Add
                </Button>
            </Inline>
            <Stack>
                <Inline vAlignItems="center" gap="small">
                {tags?.map(tag => (
                        <Tag key={tag} label={tag} isRemovable onClear={() => handleDeleteTag(tag)} />
                ))}
                </Inline>
            </Stack>
            <Divider />
        </Stack>
    );
};

const FavoritesSection = () => {
    const categories = ['labs', 'meds', 'scores'];

    return (
        <div>
            {categories.map(category => (
                <FavoriteTags key={category} category={category} />
            ))}
        </div>
    );
};

export default FavoritesSection;
