import React, { useEffect, useState } from 'react';
import { DropdownMenu } from "@amboss/design-system";
import { useNavigationItems } from '../../hooks/useNavigationItems';
import { useNavigate } from 'react-router-dom';
import {useViewport} from "../../Context/ViewportContext";
import LoginModal from "./LoginModal/LoginModal";

function NavMenu({ theme, setTheme, loggedIn }) {
    const tabs = useNavigationItems();
    const viewport = useViewport();
    const navigate = useNavigate();

    const [menuItems, setMenuItems] = useState([]);

    useEffect(() => {
        const defaultMenuItems = [
        ];

        if (viewport < 3) {
            // Append the separator and light switch to the defaultMenuItems
            defaultMenuItems.push(
                {
                    icon: 'bulb',
                    label: `${theme ? 'Lighter' : 'Darker'}`,
                    onSelect: () => setTheme(!theme)
                },
                'separator'
            );
        }

        // Add navigation items to dropdown menu
        tabs.forEach(tab => {
            defaultMenuItems.push({
                icon: tab.icon,
                label: tab.label,
                onSelect: () => {
                    if (!tab.disabled) navigate(`/${tab.label.toLowerCase()}`);
                }
            });
        });

        setMenuItems(defaultMenuItems);
    }, [viewport, theme, loggedIn]);


    return (
        <DropdownMenu
            iconName="menu"
            className="menu"
            menuItems={menuItems}
            onMenuClose={function noRefCheck(){}}
            onMenuOpen={function noRefCheck(){}}
        />
    );
}

export default NavMenu;
