import { createSlice } from '@reduxjs/toolkit';

export const searchSlice = createSlice({
    name: 'search',
    initialState: {
        results: null,
        isLoading: false,
        error: null
    },
    reducers: {
        storeSearchResults: (state, action) => {
            state.results = action.payload;
        },
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        }
    }
});

export const selectIsLoadingSearch = (state) => state.search.isLoading;

export const { storeSearchResults, setLoading, setError } = searchSlice.actions;
export default searchSlice.reducer;
