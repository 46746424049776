import React, { createContext, useState, useContext, useEffect } from 'react';

const CSRFContext = createContext({
    csrfToken: null
});

export const CSRFProvider = ({ children }) => {
    const [csrfToken, setCsrfToken] = useState(null);

    // Fetch CSRF token function
    const fetchCSRFToken = async () => {
        const response = await fetch('/api/auth/csrf-token');
        if (response.ok) {
            const data = await response.json();
            setCsrfToken(data.csrfToken);
        } else {
            console.error('Failed to refresh CSRF token');
        }
    };

    useEffect(() => {
        // Fetch the token immediately upon mount
        fetchCSRFToken();

        // Set the interval to fetch it repeatedly
        const intervalId = setInterval(fetchCSRFToken, 1000*60); // Currently set very low as tinycsrf cause problems otherwise -- look into that later

        return () => clearInterval(intervalId);
    }, []); // Only run once when component mounts, and not on every re-render

    return (
        <CSRFContext.Provider value={{ csrfToken }}>
            {children}
        </CSRFContext.Provider>
    );
}

export const useCSRF = () => {
    return useContext(CSRFContext);
}
