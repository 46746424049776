import React, { useEffect, useState } from "react";
import { Logo } from "@amboss/design-system";
import {useViewport} from "../../../Context/ViewportContext";

function CustomLogo() {
    // Handle window resize
    const viewport = useViewport();

    return viewport < 3 ? (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 249.44882 249.44882" width="21" height="21">
            <g>
                <path xmlns="http://www.w3.org/2000/svg" d="M226.733,202.686,134.715,33.98632l-8.55,16.03027-8.744,16.02929L51.17115,187.50375H33.68388l92.383-169.37222-8.646-15.851L8.1088,202.686H42.88995L26.32748,233.05049H208.51431L191.95184,202.686ZM134.81193,65.86942l66.346,121.63433H183.67064l-48.955-89.7509-8.64929,15.84838,40.31043,73.90251H68.46522Zm48.1273,151.99884H51.90256L60.18389,202.686H174.658Z" fill="#0AA7B9"/>
            </g>
        </svg>
    ) : (
        <Logo href="https://manus-media.amboss.com/2020/07/amboss-icon.svg" />
    );
}

export default CustomLogo;
