import React, { useEffect, useState } from 'react';
import {
    Box, Column, Columns, Container, Link, LoadingSpinner, MediaItem, SearchResult, Text
} from "@amboss/design-system";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentPatientConditions } from "../../../redux/slices/conditionSlice";
import {storeSearchResults, setLoading, setError, selectIsLoadingSearch} from "../../../redux/slices/searchSlice";
import {selectCurrentPatientId} from "../../../redux/slices/patientSlice";
import SearchResultItem from "./SearchResultItem";
import {stripHTML} from "../../../utils/oneLinerFunctions";

function Search() {
    const dispatch = useDispatch();
    const currentPatientConditions = useSelector(selectCurrentPatientConditions);
    const currentPatientId = useSelector(selectCurrentPatientId);
    const searchResults = useSelector(state => state.search?.results);
    const isLoadingSearch = useSelector(selectIsLoadingSearch);
    const [abstracts, setAbstracts] = useState([]);

    useEffect(() => {
        async function fetchData() {
            dispatch(setLoading(true));
            const terms = currentPatientConditions.map(e => e.display)
            console.log("Terms:",terms)
            try {
                const response = await fetch(`/api/search/amboss?media=true&articles=true`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({terms})
                });
                const data = await response.json();

                if (data.searchSuggestionInstantResults) {
                    const fetchedAbstracts = data.searchSuggestionInstantResults.map(result => result.metaData?.abstract);
                    setAbstracts(fetchedAbstracts);

                    data.searchSuggestionInstantResults.forEach(result => {
                        if (result.metaData) {
                            delete result.metaData.abstract;
                        }
                    });
                }
                dispatch(storeSearchResults(data));
            } catch (error) {
                dispatch(setError(error.message));
            } finally {
                dispatch(setLoading(false));
            }
        }

        fetchData();
    }, [currentPatientId,dispatch]);

    return (
        <Box space="m">
            { isLoadingSearch && <Box tSpace={"xs"} bSpace={"l"}> <LoadingSpinner screenReaderText={"Loading Search results"} size={searchResults ? 20 : 120} /> </Box>}
            { !isLoadingSearch && ( !searchResults || searchResults.statusCode ) && <Box tSpace={"xs"} bSpace={"l"}> <Text as={"p"}>No relevant AMBOSS articles have been found. You can try the search bar ...</Text> </Box>}
            <Columns gap={"xl"}>
                {searchResults?.searchSuggestionInstantResults?.filter(result => result.articleEid).map((result, index) => (
                    <SearchResultItem result={result} abstract={stripHTML(abstracts[index])} />
                ))}

            </Columns></Box>
    )
}

export default Search;
