import {Box, Button, Inline, LoadingSpinner, Text} from "@amboss/design-system";
import {useState} from "react";
import {useCSRF} from "../../../Context/CSRFContext";
import {useUser} from "../../../Context/UserContext";

export default function UserModalLogout({ hasLogoutDialog, setLogoutDialog, setUserModalOpen }) {
    const [isLoggingOut, setIsLoggingOut] = useState(false);
    const [logoutSuccess, setLogoutSuccess] = useState(false);
    const {csrfToken} = useCSRF();
    const {forceUserCheck} = useUser();

    const handleConfirmLogout = async () => {
        setIsLoggingOut(true);

        try {
            const response = await fetch('/api/auth/medicuja/logout', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ _csrf: csrfToken }),
            });

            const data = await response.json();

            if(!response.ok || !data.success ) {
                throw new Error(data.message || "Failed to log out.")
            }

            if (data.success) {
                console.log("Logged out successfully.")
                setLogoutSuccess(true);
                forceUserCheck();

                setTimeout(() => {
                    setUserModalOpen(false); // Close the modal after a delay
                }, 2000);
            }
        } catch (err) {
            console.error("Logout Error:", err.message);
        } finally {
            setIsLoggingOut(false)
        }
    };

    if (isLoggingOut) {
        return <LoadingSpinner screenReaderText={"Loading"} />
    }

    if (logoutSuccess) {
        return <Text>Successfully logged out.</Text>;
    }

    return (
        <>
            <Text>Are you sure you want to logout?</Text>
            <Box><Inline>
                <Button variant="secondary" onClick={() => setLogoutDialog(false)}>Go back</Button>
                <Button variant="primary" className="button--destructive" onClick={handleConfirmLogout}>Sure</Button>
            </Inline></Box>
        </>
    );
}
