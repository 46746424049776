import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

// Async thunk for fetching patient data
export const fetchPatient = createAsyncThunk(
    'patient/fetchPatient',
    async (patientId, { getState, dispatch }) => {
        try {
            console.log("Fetching patient for redux:")
            console.log(process.env.NODE_ENV)
            const endpoint = process.env.NODE_ENV !== 'production'
                ? `/api/patient/data/${patientId || ""}`
                : `/api/patient/data/${patientId || ""}`;

            const response = await fetch(endpoint);
            console.log("Responded with: \n",response)
            if (!response.ok || response.status === 204) {
                throw new Error('Network response was not ok or empty');
            }
            return await response.json();
        } catch (error) {
            console.error("Error fetching patient data: ", error);
            throw error;
        }
    }
);

export const patientSlice = createSlice({
    name: 'patient',
    initialState: {
        patients: [],
        currentPatient: null,
        status: 'idle',
        error: null
    },
    reducers: {
        clearPatientData: (state) => {
            state.patients = [];
            state.currentPatient = null;
            state.status = 'idle';
            state.error = null;
        },
        setCurrentPatient: (state, action) => {
            state.currentPatient = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPatient.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchPatient.fulfilled, (state, action) => {
                if (action.payload && Array.isArray(action.payload.entry)) {
                    state.patients = action.payload.entry;
                } else if (action.payload) {
                    state.patients = [action.payload];
                    state.currentPatient = action.payload;
                } else {
                    console.error('Unexpected payload:', action.payload);
                }
                state.status = 'succeeded';
            })

            .addCase(fetchPatient.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    }
});

export const selectCurrentPatientId = (state) => state.patient.currentPatient?.resource?.id || state.patient.currentPatient?.id;
export const selectCurrentPatientName = (state) => state.patient.currentPatient?.resource?.name[0]?.family || state.patient.currentPatient?.name[0]?.family;
export const selectCurrentPatientFirstName = (state) => state.patient.currentPatient?.resource?.name[0]?.given.join(' ') || state.patient.currentPatient?.name[0]?.given?.join(' ');

export const { clearPatientData, setCurrentPatient } = patientSlice.actions;
export default patientSlice.reducer;
