import React, { createContext, useContext, useState, useEffect } from 'react';

const ViewportContext = createContext();

export const ViewportProvider = ({ children }) => {
    const [viewport, setViewport] = useState(3); // default to large

    useEffect(() => {
        const setViewportSize = () => {
            if (window.innerWidth < 576) {
                setViewport(0)
            } else if (window.innerWidth < 768) {
                setViewport(1);
            } else if (window.innerWidth < 992) {
                setViewport(2);
            } else if (window.innerWidth < 1280) {
                setViewport(3);
            } else {
                setViewport(4);
            }
        };

        setViewportSize();
        window.addEventListener('resize', setViewportSize);

        return () => {
            window.removeEventListener('resize', setViewportSize);
        };
    }, []);

    return (
        <ViewportContext.Provider value={viewport}>
            {children}
        </ViewportContext.Provider>
    );
}

export const useViewport = () => useContext(ViewportContext);
