
function trimLeadingNumbers(query) {
    return query.replace(/^\d+\.\s*/, '');
}

export default async function fetchRecommendedSearchQueries (params) {
    const {prompt, currentPatient, currentUser, setRecommendedQueries, isMounted, csrfToken} = params;
    try {
        const response = await fetch('/api/search/queries/suggestions', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                userEid: currentUser.eid,
                patientId: currentPatient?.id,
                patientBirthDate: currentPatient?.birthDate,
                prompt,
                _csrf: csrfToken
            }),
        });
        const data = await response.json();

        if (data && data.queries && isMounted.current) {
            setRecommendedQueries(data.queries.map(q => ({id: q.id, text: trimLeadingNumbers(q.text)}) ));
            /*
             only use manually entered searches for the history for now
             */
            // localStorage.setItem('recentSearches', JSON.stringify([...recentSearches, ...data.queries]));
            return data;
        }
    } catch (error) {
        if (isMounted.current) {
            console.error("Error fetching recommended queries:", error);
            return error;
        }
    }
};
