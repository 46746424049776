
export default async function handleSearchQueryDeletion(params) {
    console.log("handleDelete:",params)
    const { queryId, queryText, currentUser, currentPatient, csrfToken, recommendedQueries, setRecommendedQueries } = params;
    try {
        await fetch(`/api/search/queries/suggestions/${queryId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                userEid: currentUser.eid,
                patientId: currentPatient?.id,
                patientBirthDate: currentPatient?.birthDate,
                _csrf: csrfToken
            }),
        });

        const newQueries = recommendedQueries.filter(q => q.text !== queryText);
        setRecommendedQueries(newQueries);
    } catch (error) {
        console.error("Error deleting query:", error);
    }
};
