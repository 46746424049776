// toolsSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const toolsSlice = createSlice({
    name: 'tools',
    initialState: {
        calculator: {
            data: null,
            loading: true
        },
        translator: {
            data: null,
            loading: true
        },
        // ... other tools
    },
    reducers: {
        setCalculatorData: (state, action) => {
            state.calculator.data = action.payload;
            state.calculator.loading = false;
        },
        setTranslatorData: (state, action) => {
            state.translator.data = action.payload;
            state.translator.loading = false;
        },
        // ... other setters
    }
});

export const { setCalculatorData, setTranslatorData } = toolsSlice.actions;

export default toolsSlice.reducer;
