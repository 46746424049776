// observationSlice.js
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

export const fetchObservation = createAsyncThunk(
    'observation/fetchObservation',
    async (patientId) => {
        try {
            const endpoint = process.env.NODE_ENV !== 'production'
                ? `/api/patient/observations/${patientId || ""}`
                : "/api/patient/observations";

            const response = await fetch(endpoint);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return await response.json();
        } catch (error) {
            console.error("Error fetching observation data: ", error);
            throw error;
        }
    }
);

export const observationSlice = createSlice({
    name: 'observation',
    initialState: {
        data: null,
        status: 'idle',  // This can be 'idle', 'loading', 'succeeded', 'failed'
        error: null
    },
    reducers: {
        startFetchingObservation: (state) => {
            state.status = 'loading';
        },
        observationFetched: (state, action) => {
            state.status = 'succeeded';
            state.data = action.payload;
        },
        observationFetchFailed: (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        },
        clearObservationData: (state) => {
            state.data = null;
            state.status = 'idle';
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchObservation.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchObservation.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload;
            })
            .addCase(fetchObservation.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    }
});

export const { startFetchingObservation, observationFetched, observationFetchFailed, clearObservationData } = observationSlice.actions;

export default observationSlice.reducer;
