
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {fetchPatient} from "../../../redux/slices/patientSlice";
import {Modal, Link, Pagination, Text, LoadingSpinner, Inline, Box, Input} from "@amboss/design-system";
import MiniSearch from 'minisearch'
function PatientSelectModal ({ open, isPatientSelectModalOpen, setPatientSelectModalOpen }) {

    const [currentPage, setCurrentPage] = useState(1);
    const [patients, setPatients] = useState([])
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredPatients, setFilteredPatients] = useState([]);

    const miniSearch = new MiniSearch({
        fields: ['name', 'dateOfBirth']
    });

    const itemsPerPage = 10;
    const dispatch = useDispatch();

    const handlePatientSelect = (id) => {
        dispatch(fetchPatient(id));
        setPatientSelectModalOpen(false);
    };

    const handlePagination = (direction) => {
        if (direction === 'next') {
            setCurrentPage(prevPage => prevPage + 1);
        } else {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };

    useEffect(() => {
        fetch('/api/patients')
            .then(res => res.json())
            .then(data => {
                setPatients(data);
                setFilteredPatients(data); // Initialize filteredPatients
                miniSearch.addAll(data);
            });
    }, []);

    useEffect(() => {

        if (searchTerm.length > 3) {
            const results = miniSearch.search(searchTerm, { fields: ['name', 'dateOfBirth']});
            console.log(results)
            setFilteredPatients(results);
        } else {
            setFilteredPatients(patients);
        }
    }, [searchTerm]);


    return (
            <Modal
                header="Select Patient"
                labelHeader="Working on another patient?"
                onAction={(action) => {
                    console.log(action, open)
                    if (action === "cancel" && open === true) {
                        console.log("Why dont I close?")
                        setPatientSelectModalOpen(false)
                    } else if (action === "action") {
                        console.log("Why dont I close still?")
                        setPatientSelectModalOpen(false);
                    }
                    open = true;
                }}
                cancelButtonLabel="Close"
                privateProps={{ skipPortal: false }}
                role="dialog"
            >
                { patients.length < 1
                    ? <LoadingSpinner screenReaderText={"Loading patients"} size={60} />
                    :
                       <>
                           <Box lSpace={"zero"} bSpace={"l"} space={"m"}><Input
                               name="patient-searchbox"
                               label="Search patient"
                               onBlur={function noRefCheck(){}}
                               onChange={e => setSearchTerm(e.target.value)}
                               onClick={function noRefCheck(){}}
                               onFocus={function noRefCheck(){}}
                               placeholder="Search Buttlicker or birth date ..."
                               type="text"
                               value={searchTerm}
                           /></Box>
                           <Modal.Stack>
                               {searchTerm && patients.length === 0
                                   ? <Text>No patients found for your search.</Text>
                                   : patients.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map(patient => (
                                       <Inline key={patient.id} alignItems={"spaceBetween"}>
                                           {patient.name && <Text>{patient.name}, born {patient.dateOfBirth}</Text>}
                                           {patient.id && <Link onClick={() => {
                                               handlePatientSelect(patient.id)
                                           }}>Select</Link>}
                                       </Inline>
                                   ))}
                            </Modal.Stack>
                            <Box tSpace={"xl"} space={"m"} alignText={"center"}><Inline alignItems={"center"}>
                                <Pagination
                                numOfItems={patients.length}
                                numOfItemsPerPage={itemsPerPage}
                                onNextClick={() => handlePagination('next')}
                                onPrevClick={() => handlePagination('prev')}
                                currentPage={currentPage} /></Inline>
                            </Box>
                       </>
                 }
            </Modal>
    );
}

export default PatientSelectModal;

/*

<Modal.Footer>
    <Pagination
        numOfItems={patients.length}
        numOfItemsPerPage={itemsPerPage}
        onNextClick={() => handlePagination('next')}
        onPrevClick={() => handlePagination('prev')}
        currentPage={currentPage} />
</Modal.Footer>
 */
