import React from 'react';
import CompanyLinks from './CompanyLinks';
import Terms from './Terms';

function Footer() {
    return (
        <div className="footer">
            <CompanyLinks />
            <Terms />
        </div>
    );
}

export default Footer;
