// Translator.js
import React, { useState, useEffect } from 'react';
import {
    Card,
    Dropdown,
    Button,
    FormControl,
    InputGroup,
    Placeholder,
    FloatingLabel,
    Form,
    Row,
    Col
} from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faLanguage, faArrowDown, faArrowUp, faArrowsUpDown, faArrowsLeftRight} from "@fortawesome/free-solid-svg-icons";
import { TileTitle, TileDescription } from "../../Core/Tile/TileComponents";

function TranslatorTile() {
    const [sourceText, setSourceText] = useState('');
    const [translatedText, setTranslatedText] = useState('');
    const [sourceLanguage, setSourceLanguage] = useState('EN');
    const [targetLanguage, setTargetLanguage] = useState('DE');
    const [tokenUsage, setTokenUsage] = useState(0);

    const [csrfToken, setCsrfToken] = useState('');

    useEffect(() => {
        // Fetch CSRF token on component mount
        fetch('/api/auth/csrf-token')
            .then(res => res.json())
            .then(data => setCsrfToken(data.csrfToken))
            .catch(err => console.error("Error fetching CSRF token", err.message));
    }, []);

    useEffect(() => {
        // Fetch token usage on component mount
        fetch('/api/tools/translator/tokens')
            .then(res => res.json())
            .then(data => setTokenUsage(data.tokensUsedToday))
            .catch(err => console.error("Error fetching tokens used", err.message));
    }, []);

    useEffect(() => {
        const timeout = setTimeout(async () => {
            if (sourceText.length >= 5 && sourceText.length <= 200) {

                const response = await fetch('/api/tools/translator/translate', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        sourceText,
                        sourceLanguage,
                        targetLanguage,
                        _csrf: csrfToken,
                    })
                });

                const data = await response.json();
                console.log("Translation receiveid:\n",data)
                setTranslatedText(data.translatedText);
            }
        }, 5000);

        return () => clearTimeout(timeout);
    }, [sourceText, csrfToken, sourceLanguage, targetLanguage]);

    const swapFields = () => {
        setSourceText(translatedText);
        setTranslatedText(sourceText);
        setSourceLanguage(targetLanguage);
        setTargetLanguage(sourceLanguage);
    };

    return (
        <Card className="tile">
            <Card.Header className="d-flex justify-content-center align-items-center" style={{ height: '180px' }}>
                <FontAwesomeIcon icon={faLanguage} size="5x" />
            </Card.Header>
            <Card.Body>
                <TileTitle>Translator</TileTitle>
                <TileDescription>Select Language for Translation</TileDescription>

                <Row className="align-items-center mb-3">
                    <Col md="5">
                        <FloatingLabel controlId="sourceLanguageFloatingSelect" label="Source/From" className="mb-2">
                            <Form.Select
                                aria-label="Floating label select example for source language"
                                value={sourceLanguage}
                                onChange={(e) => setSourceLanguage(e.target.value)}
                                style={{ height: 'calc(1.5em + .75rem + 2px)' }}  // Adjusts the height
                            >
                                <option value="EN">English</option>
                                <option value="ES">Espanol</option>
                                <option value="DE">German</option>
                                <option value="IT">Italian</option>
                                <option value="PL">Polish</option>
                            </Form.Select>
                        </FloatingLabel>
                    </Col>
                    <Col md="2" className="d-flex justify-content-center">
                        <Button className="text-center" variant="link" onClick={swapFields}>
                            <FontAwesomeIcon icon={faArrowsLeftRight} />
                        </Button>
                    </Col>
                    <Col md="5">
                        <FloatingLabel controlId="targetLanguageFloatingSelect" label="Target/To" className="mb-2">
                            <Form.Select
                                aria-label="Floating label select example for target language"
                                value={targetLanguage}
                                onChange={(e) => setTargetLanguage(e.target.value)}
                                style={{ height: 'calc(1.5em + .75rem + 2px)' }}  // Adjusts the height
                            >
                                <option value="ES">Espanol</option>
                                <option value="EN">English</option>
                                <option value="DE">German</option>
                                <option value="IT">Italian</option>
                                <option value="PL">Polish</option>
                            </Form.Select>
                        </FloatingLabel>
                    </Col>
                </Row>

                <FormControl
                    as="textarea"
                    value={sourceText}
                    onChange={(e) => setSourceText(e.target.value)}
                    placeholder="Enter source text here..."
                    style={{ height: '100px', textAlign: 'left', whiteSpace: 'pre-wrap' }} // Aligns text to top-left
                    className="mb-2"
                />

                <FormControl
                    as="textarea"
                    value={translatedText}
                    onChange={(e) => setTranslatedText(e.target.value)}
                    placeholder="Translation will appear here..."
                    style={{ height: '100px', textAlign: 'left', whiteSpace: 'pre-wrap' }} // Aligns text to top-left
                />
            </Card.Body>
            <Card.Footer>
                <Row>
                    <Col>
                        <p className="text-end mb-0" style={{ color: sourceText.length < 5 ? 'red' : 'black' }}>
                            Characters: {sourceText.length}/200
                        </p>
                    </Col>
                    <Col>
                        <p className="text-end mb-0">
                            Tokens used today: {tokenUsage || 0}
                        </p>
                    </Col>
                </Row>
            </Card.Footer>



        </Card>
    );
}

export default TranslatorTile;
