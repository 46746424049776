export const pageIconMap = {
    'home': "home",
    'tools': "stethoscope", // or "hammer-filled" or "box"
    'calculator': "calculator",
    'medication': "pill",
    'patient': "med-imaging",
    'settings': "settings",
    'articles': "article",
    'quiz': "quiz",
    'links': "link",
    'menu': "menu",
    'info': "info",
    'break': "coffee",
    'userCenter': "users",
    'institution': "institution",
    'amboss':"amboss",
    // unreviewed
    'tables': "table",
    'notifications': "bell",
    'alerts': "alert-circle",
    'downloads': "download",
    'editing': "edit-3",
    'messages': "message-circle",
    'search': "search",
};
