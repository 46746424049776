import SearchBar from "./SearchBar";
import SearchSuggestions from "./SearchSuggestions";
import React from "react";
import useLocalStorage from "../../../hooks/useLocalStorage";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectCurrentUserEid} from "../../../redux/slices/userSlice";

export default function SearchWrapper({searchBarWrapper, showSearchSuggestions, setShowSearchSuggestions}) {

    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useLocalStorage('searchTerm', '');
    const currentUserEid = useSelector(selectCurrentUserEid);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSearchFocus = () => {
        setShowSearchSuggestions(true);
    };

    const handleSearchSubmit = async () => {
        if (searchTerm.length >= 3) {
            const userEid = currentUserEid;
            try {
                const response = await axios.post('/api/search/queries/save', { searchTerm, userEid: currentUserEid });

                if (response.data.searches) {
                    localStorage.setItem('recentSearches', JSON.stringify(response.data.searches));
                }

                // Redirect to the dashboard
                navigate('/', { state: { searchTerm } });
                setShowSearchSuggestions(false);
            } catch (error) {
                console.error('Error saving search:', error);
            }
        }
    };

    return(
        <div ref={searchBarWrapper} className={"search-wrapper"} >
            <SearchBar
                className={"searchbar"}
                onFocus={handleSearchFocus}
                handleSearchSubmit={handleSearchSubmit}
                searchTerm={searchTerm}
                setShowSearchSuggestions={setShowSearchSuggestions}
                onInputChange={handleSearchChange}
                onMouseEnter={() => setShowSearchSuggestions(true)}
            />
            {showSearchSuggestions &&
                <SearchSuggestions
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    handleSearchSubmit={handleSearchSubmit}
                    onMouseEnter={() => setShowSearchSuggestions(true)}
                    onClick={() => setShowSearchSuggestions(false)}
                />}
        </div>
    )
}
