
import React, {useState} from 'react';
import { Callout} from "@amboss/design-system";
import PatientSelectModal from "./PatientSelectModal";

function PatientCallout ({ patientStatus, currentPatientFirstName, currentPatientName, currentPatientId }) {
    const [isPatientSelectModalOpen, setPatientSelectModalOpen] = useState(false);

    const patientInfoContent = () => {
        if (patientStatus === 'loading') {
            return "Loading …";
        } else if (patientStatus === 'succeeded') {
            return `Selected patient: ${currentPatientFirstName} ${currentPatientName} | Patient ID: ${currentPatientId}`;
        } else if (patientStatus === 'succeeded' && !currentPatientId) {
            return "Select patient …"; // Handle onClick to show modal
        } else {
            return "No patient selected";
        }
    };

    return (
        <div className={"patient-callout__wrapper"} style={{cursor: "pointer"}}
             onClick={(e) => {
                 e.preventDefault();
                 setPatientSelectModalOpen(true)
             }}>
            <Callout
                text={patientInfoContent()}
                icon={
                    patientStatus === 'loading'
                        ? 'alert-circle'
                        : patientStatus === 'succeeded'
                            ? 'check-circle'
                            : 'x-circle-filled'
                }
                type={
                    patientStatus === 'loading'
                        ? 'warning'
                        : patientStatus === 'succeeded'
                            ? 'success'
                            : 'error'
                }
            />
        {isPatientSelectModalOpen &&
            <PatientSelectModal
                open={false}
                isPatientSelectModalOpen={isPatientSelectModalOpen}
                setPatientSelectModalOpen={setPatientSelectModalOpen}
            />
        }
        </div>
    );
}


export default PatientCallout
