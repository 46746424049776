// conditionSlice.js
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

export const fetchCondition = createAsyncThunk(
    'condition/fetchCondition',
    async (patientId) => {
        try {
            const endpoint = process.env.NODE_ENV !== 'production'
                ? `/api/patient/conditions/${patientId || ""}`
                : "/api/patient/conditions";

            const response = await fetch(endpoint);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return await response.json();
        } catch (error) {
            console.error("Error fetching condition data: ", error);
            throw error;
        }
    }
);

export const conditionSlice = createSlice({
    name: 'condition',
    initialState: {
        data: null,
        status: 'idle',  // This can be 'idle', 'loading', 'succeeded', 'failed'
        error: null
    },
    reducers: {
        startFetchingCondition: (state) => {
            state.status = 'loading';
        },
        conditionFetched: (state, action) => {
            state.status = 'succeeded';
            state.data = action.payload;
        },
        conditionFetchFailed: (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        },
        clearConditionData: (state) => {
            state.data = null;
            state.status = 'idle';
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCondition.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchCondition.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload;
            })
            .addCase(fetchCondition.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    }
});


export const selectCurrentPatientConditions = (state) => {
    const conditionData = state.condition.data;

    if (!conditionData || !conditionData.entry) {
        return [];
    }

    return conditionData.entry.filter(e => e.resource.code).map(entry => {
        const condition = entry.resource;
        return {
            ...condition.code.coding[0],
            onsetDateTime: new Date(condition.onsetDateTime)
        };
    });
};

export const { startFetchingCondition, conditionFetched, conditionFetchFailed, clearConditionData } = conditionSlice.actions;
export default conditionSlice.reducer;
